<script>
import config from "@/common/config/app.config.json";
import { staffCookies } from "@/common/mixins/mix_cookies";

export default {
  name: "AuthSSO",
  mixins: [staffCookies],
  data() {
    return {
      user: null,
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * set cookies & localStorage after login success
     * --------------------------------------------------------------
     */
    setCookies: function (staff) {
      this.cookies.add({
        isLogin: true,
        isOnline: false, // default away
        staff: {
          id: staff.id,
          name: staff.nama,
          email: staff.email,
          deptid: staff.deptid,
          jabatan: staff.jabatan,
        },
      });

      // set token
      localStorage.setItem(config.rwStorage.agent.token, staff.token);
      this.$router.push("/agent");
    },

    customLogin: function (firebase_token) {
      this.$store
        .dispatch("FbTokenLogin", firebase_token)
        .then(() => {
          this.setCookies(this.user);
        })
        .catch((err) => {
          this.$router.push({ path: "/?error=" + err });
        });
    },
  },
  mounted() {
    try {
      if (typeof this.$route.query.token === "undefined") {
        throw "No Token provided :1";
      }

      let user = atob(this.$route.query.token);
      user = JSON.parse(user);
      if (typeof user.token === "undefined") {
        throw "No Token provided :2";
      }

      this.user = user;
      this.customLogin(user.fbtoken);
    } catch (e) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<template>
  <div class="w-screen h-screen flex flex-col items-center justify-center">
    <img src="/img/rw/rw.png" alt="online" class="animate-bounce w-20" />
    <h1 class="text-4xl">Loading ...</h1>
  </div>
</template>
